@font-face {
  font-family: "Pretendard";
  src: url("../assets/font/Pretendard-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "Pretendard";
  src: url("../assets/font/Pretendard-SemiBold.otf");
  font-weight: 600;
}

@font-face {
  font-family: "Pretendard";
  src: url("../assets/font/Pretendard-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Pretendard";
  src: url("../assets/font/Pretendard-Regular.otf");
  font-weight: 400;
}
